import { useDispatch } from 'react-redux';
import { getFileType } from '@utils/files';
import {
  setPreviewTitle,
  setPreviewType,
  setShowPreview,
  setShowUploadButton,
  setUrl,
} from '@states/slices/filePreviewSlices';
import { PreviewType } from '@utils/types';
import PdfPreview from '@components/PdfPreview';
import { MessageFileProps } from './Message.type';

function MessageFile({ url }: MessageFileProps) {
  const dispatch = useDispatch();

  function handleFileClick(fileType: PreviewType, fileUrl: string) {
    dispatch(setPreviewType(fileType));
    dispatch(setPreviewTitle(`Selected ${fileType}`));
    dispatch(setShowPreview(true));
    dispatch(setShowUploadButton(false));
    dispatch(setUrl(fileUrl));
  }

  function DisplayFileFromUrl(url: string) {
    const urlFileType = getFileType(url);

    switch (urlFileType) {
      case 'image':
        return (
          <img
            className='chat-image'
            src={url}
            alt='Selected file'
            onClick={() => {
              if (url) {
                handleFileClick('image', url);
              }
            }}
          />
        );
      case 'video':
        return (
          <div
            className='video'
            onClick={(e) => {
              e.preventDefault();
              if (url) {
                handleFileClick('video', url);
              }
            }}
          >
            <video className='chat-video' src={url}>
              Your browser does not support the video tag.
            </video>
            <div className='play-overlay'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <polygon points='5 3 19 12 5 21 5 3'></polygon>
              </svg>
            </div>
          </div>
        );
      case 'pdf':
        return (
          <div className='chat-document'>
            <PdfPreview url={url} thumbnail={true} />
            <div
              onClick={(e) => {
                e.preventDefault();
                if (url) {
                  handleFileClick('pdf', url);
                }
              }}
              className='clickable-overlay'
            ></div>
          </div>
        );
      default:
        return null;
    }
  }

  return <>{DisplayFileFromUrl(url)}</>;
}

export default MessageFile;
