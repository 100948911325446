import { RootState } from '@states/store';
import { useDispatch, useSelector } from 'react-redux';
import { getGradientBackground } from '@utils/utils';
import arrowRightIcon from '../assets/arrow-right.svg';
import chatIcon from '../assets/chat-icon.svg';
import closeIcon from '../assets/close-icon.png';
import { toggleOpenChatWindow } from '@states/slices/chatbotSlice';
import useCurrentSkin from '@hooks/useCurrentSkin';

function DefaultOpenButton() {
  const { color1, color2, buttonText } = useSelector(
    (state: RootState) => state.settings
  );
  const { openChatWindow } = useSelector((state: RootState) => state.chatbot);
  const dispatch = useDispatch();
  const currentSkin = useCurrentSkin();

  return (
    <>
      {openChatWindow !== 'OPEN' ? (
        <div className='alternate-button-container'>
          <button
            style={
              currentSkin.flags.openButtonGradient
                ? { background: getGradientBackground({ color1, color2 }) }
                : {}
            }
            onClick={() => dispatch(toggleOpenChatWindow())}
            aria-label='Open chat window'
          >
            <img src={chatIcon} alt='' />
            {buttonText}
            <img src={arrowRightIcon} alt='' />
          </button>
        </div>
      ) : (
        <div className='close-container'>
          <button
            style={
              currentSkin.flags.openButtonGradient
                ? { background: getGradientBackground({ color1, color2 }) }
                : {}
            }
            onClick={() => dispatch(toggleOpenChatWindow())}
            aria-label='Close chat window'
          >
            <img src={closeIcon} alt='' />
          </button>
        </div>
      )}
    </>
  );
}

export default DefaultOpenButton;
