import { useSelector } from 'react-redux';
import { getGradientBackground } from '@utils/utils';
import { RootState } from '@states/store';
import { IoMdStar } from 'react-icons/io';
import useCurrentSkin from '@hooks/useCurrentSkin';

function FeedbackMessage() {
  const { feedbackData } = useSelector((state: RootState) => state.chatbot);
  const { color1, color2 } = useSelector((state: RootState) => state.settings);
  const currentSkin = useCurrentSkin();

  return (
    <>
      <div
        className='chatbot-reply'
        aria-label="User feedback"
        style={
          currentSkin.flags.chatbotReplyGradient
            ? { background: getGradientBackground({ color1, color2 }) }
            : {}
        }
      >
        <b
          className={currentSkin.css.chatbotArrow}
          style={{ borderColor: color1 }}
          aria-hidden="true"
        ></b>
        <div className='rating-main-div review-main'>
          <div>
            {[...Array(feedbackData.userRating)].map((_star, i) => {
              const ratingValue = i + 1;

              return (
                <label key={i}>
                  <input 
                    type='radio' 
                    name='rating' 
                    value={ratingValue}
                    aria-label={`${ratingValue} star${ratingValue !== 1 ? 's' : ''}`}
                  />
                  <IoMdStar
                    key={i}
                    className='star'
                    size={28}
                    color={ratingValue ? '#FFBE3D' : '#e4e5e9'}
                    aria-hidden="true"
                  />
                </label>
              );
            })}
          </div>
          <div>
            <p>{feedbackData.userFeedback}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedbackMessage;
