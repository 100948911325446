import {
  setIsInputSent,
  setIsQuestionInput,
  setUserInput,
} from '@states/slices/chatbotSlice';
import { useDispatch, useSelector } from 'react-redux';
import './SuggestedQuestions.scss';
import { RootState } from '@/states/store';

function SuggestedQuestions() {
  const { suggestedQuestions, chatHistory } = useSelector(
    (state: RootState) => state.chatbot
  );
  const dispatch = useDispatch();

  function handleResponseButton(SuggestedQuestion: string) {
    dispatch(setUserInput(SuggestedQuestion));
    dispatch(setIsQuestionInput(true));
    dispatch(setIsInputSent(true));
  }

  return (
    <>
      {suggestedQuestions.length > 0 ? (
        <div
          className={`suggested-questions-container ${
            chatHistory.length < 2 ? 'first-message' : ''
          }`}
        >
          <div
            className={`responses-container size-${suggestedQuestions.length}`}
          >
            {suggestedQuestions
              .slice()
              .sort((a, b) => a.length - b.length)
              .map((question, index) => (
                <button
                  key={index}
                  className={`suggested-response-button`}
                  onClick={() => handleResponseButton(question)}
                  title={question}
                >
                  {question}
                </button>
              ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default SuggestedQuestions;
