import { useState } from 'react';
import { IoMdStar } from 'react-icons/io';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Feedback } from '@utils/types';
import { useDispatch, useSelector } from 'react-redux';

import {
  setFeedbackData,
  handleSuggestNewChat,
} from '@states/slices/chatbotSlice';
import { RootState } from '@states/store';
import conversationService from '@services/conversationService';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { INPUT_LIMIT_BY_CHAR } from '@utils/constants';

function FeedbackSuggestion() {
  const [hover, setHover] = useState<number | null>(null);
  const { currentLanguage, isFreemiumModeEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  const { currentConversation, feedbackData } = useSelector(
    (state: RootState) => state.chatbot
  );
  const dispatch = useDispatch();

  async function handleSubmitFeedback() {
    const newFeedback: Feedback = {
      userFeedback: feedbackData.userFeedback,
      userRating: feedbackData.userRating,
    };

    try {
      await conversationService.saveFeedback(newFeedback, currentConversation);
      toast.success(currentLanguage.feedback.success);
      dispatch(setFeedbackData({ ...newFeedback, submitted: true }));
      dispatch(handleSuggestNewChat());
    } catch (error: any) {
      toast.error(currentLanguage.feedback.error);
    }
  }

  function handleFeedbackInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.value.length > INPUT_LIMIT_BY_CHAR) {
      toast.error('Feedback has exceeded character limit');
      return;
    }
    dispatch(
      setFeedbackData({ ...feedbackData, userFeedback: e.target.value })
    );
  }

  function handleFeedbackRatingSelection(rating: number) {
    dispatch(setFeedbackData({ ...feedbackData, userRating: rating }));
  }

  return (
    <>
      <div
        className={`rating-wrapper ${
          isFreemiumModeEnabled ? 'with-watermark' : ''
        }`}
      >
        <h2>{currentLanguage.feedback.requestFeedback}</h2>
        <div className='rating-main-div'>
          {[...Array(5)].map((_star, i) => {
            const ratingValue = i + 1;

            return (
              <label key={i}>
                <input
                  type='radio'
                  name='rating'
                  value={ratingValue}
                  aria-label={`${ratingValue} star${ratingValue !== 1 ? 's' : ''}`}
                  onClick={() => handleFeedbackRatingSelection(ratingValue)}
                />
                <IoMdStar
                  key={i}
                  className='star'
                  size={28}
                  color={
                    ratingValue <= (hover ?? feedbackData.userRating ?? 0)
                      ? '#FFBE3D'
                      : '#e4e5e9'
                  }
                  aria-hidden="true"
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
        <div className='rating-form-div'>
          <ReactTextareaAutosize
            onInput={handleFeedbackInput}
            value={feedbackData.userFeedback || ''}
            className='feedback-textarea'
            aria-label='Input feedback'
          />
          <div className='feedback-btn-row'>
            <button
              className='feedback-cancel-btn'
              onClick={() => dispatch(handleSuggestNewChat())}
            >
              {currentLanguage.feedback.cancelButton}
            </button>
            <button
              className='feedback-btn'
              disabled={!feedbackData.userRating}
              onClick={handleSubmitFeedback}
            >
              {currentLanguage.feedback.sendButton}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedbackSuggestion;
