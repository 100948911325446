import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import ChatContainer from '@components/ChatContainer';
import { ToastContainer } from 'react-toastify';
import { getFileSrc } from '@utils/files';
import {
  setIsEmbeddedModeEnabled,
  setIsFullScreenModeEnabled,
  setIsMobileModeEnabled,
  setIsPasswordEnabled,
  setIsSettingsEnabled,
  setIsSettingsPresetLoaded,
  setSettingsPreset,
  updateSavedSettings,
} from '@states/slices/settingsSlice';
import {
  setDisplayChatbot,
  setCurrentUser,
  setIsValidUser,
  setOpenChatWindow,
  setWaitingForBotReply,
  setIsInputDisabled,
  setSavedCookies,
} from '@states/slices/chatbotSlice';
import { useEffect, useState } from 'react';
import { SettingsState } from '@utils/types';
import usersService from '@services/usersService';
import { useParams } from 'react-router-dom';
import useCurrentSkin from '@hooks/useCurrentSkin';
import { setCurrentSkin } from '@states/slices/skinsSlice';
import ChatPreview from '@components/ChatPreview';
import '@styles/pages/Home.scss';
import settingsService from '@services/settingsService';
import eventsService from '@services/eventsService';
import validationService from '@services/validationService';
import { useIdleEventTimeout } from '@hooks/useIdleEventTimeout';
import useWebSockets from '@hooks/useWebSockets';

function Home() {
  const {
    botBackground,
    abTestPercentage,
    demoMode,
    isEmbeddedModeEnabled,
    isMobileModeEnabled,
    isFullScreenModeEnabled,
    isPasswordEnabled,
    isSettingsPresetLoaded,
    isErrorDisplayEnabled,
    isAuthedUsersOnlyEnabled,
  } = useSelector((state: RootState) => state.settings);
  const { openChatWindow, displayChatbot, currentUser } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { currentSkinName } = useSelector((state: RootState) => state.skins);
  const [iframeOriginUrl, setIframeOriginUrl] = useState('');
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const { company } = useParams();
  const currentSkin = useCurrentSkin();
  const dispatch = useDispatch();
  const { checkIdleTimeout } = useIdleEventTimeout();

  useWebSockets();

  async function validateIframeOrigin(parentUrl: string): Promise<any> {
    const parentDomain = new URL(parentUrl).hostname;
    const directDomain = new URL(window.location.origin).hostname;
    console.info(
      'Validating iframe origin:',
      parentDomain,
      directDomain,
      parentUrl
    );
    const { isOriginValid, botVariant } =
      await validationService.validateIframeOrigin(
        parentDomain,
        directDomain,
        parentUrl
      );
    return { isOriginValid, botVariant };
  }

  function handleCompanyConfig(companySettings: SettingsState): void {
    console.info('Updating company settings for:', companySettings.botName);
    dispatch(updateSavedSettings({ ...companySettings }));
    dispatch(setCurrentSkin(companySettings.skinName));
    if (companySettings.pageTitle) {
      document.title = companySettings.pageTitle;
    }
    if (companySettings.favicon) {
      const link = document.querySelector(
        "link[rel~='icon']"
      ) as HTMLLinkElement;
      if (!link) {
        const newLink = document.createElement('link') as HTMLLinkElement;
        newLink.rel = 'icon';
        newLink.href = companySettings.favicon;
        document.getElementsByTagName('head')[0].appendChild(newLink);
      } else {
        link.href = companySettings.favicon;
      }
    }
    dispatch(setIsSettingsPresetLoaded(true));
  }

  async function getSandboxSettings(company: string): Promise<void> {
    try {
      const companySettings = await settingsService.fetchSavedSettings(company);
      handleCompanyConfig(companySettings);
      dispatch(setSettingsPreset(company));
      dispatch(setIsPasswordEnabled(false));
      dispatch(setIsSettingsEnabled(false));
      dispatch(setIsValidUser(true));
    } catch (error) {
      console.error('Error fetching company settings:', error);
    }
  }

  function isChatbotVisible(
    externalUserId: string,
    isAbEnabled: boolean
  ): boolean {
    if (isAuthedUsersOnlyEnabled && !externalUserId) {
      console.info(
        'Chatbot is only open to authenticated users. Hiding chatbot'
      );
      return false;
    }
    const chatbotVisible = isAbEnabled
      ? Math.random() * 100 < abTestPercentage
      : true;
    console.info('Chatbot visibility status is:', chatbotVisible);
    return chatbotVisible;
  }

  async function setNewUser(
    isAbEnabled: boolean,
    externalUserId: string = ''
  ): Promise<void> {
    const newUserId = uuidv4();
    console.info(
      `Setting new user with ID: ${newUserId} and external ID: ${
        externalUserId ? externalUserId : 'N/A'
      } with AB test enabled: ${isAbEnabled} and open only to authenticated: ${isAuthedUsersOnlyEnabled}`
    );
    const chatbotVisible = isChatbotVisible(externalUserId, isAbEnabled);
    localStorage.setItem('currentUser', newUserId);
    dispatch(setCurrentUser(newUserId));
    await usersService.addNewUser(newUserId, externalUserId, chatbotVisible);
    dispatch(setDisplayChatbot(chatbotVisible));
  }

  async function initiateUserAndConversation(
    externalUserId: string = '',
    isAbEnabled = false
  ): Promise<void> {
    const savedUserId = localStorage.getItem('currentUser') || '';
    console.info(
      `Initiating user and conversation with external user ID: ${
        externalUserId ? externalUserId : 'N/A'
      } and saved user ID: ${savedUserId}`
    );
    if (!savedUserId) {
      console.info('No user ID found. Setting new user.');
      await setNewUser(isAbEnabled, externalUserId);
      return;
    }
    const { userFound, userData } = await usersService.getSavedUserInfo(
      savedUserId
    );
    if (!userFound) {
      console.info('Saved user not found. Setting new user:', externalUserId);
      await setNewUser(isAbEnabled, externalUserId);
      return;
    }
    console.info(
      `User found: ${savedUserId}. Chatbot visible: ${userData.chatbot_visible}. External ID: ${userData.external_id}`
    );
    dispatch(setCurrentUser(savedUserId));
    if (userData.external_id !== externalUserId) {
      console.info(
        `External user ID updated. Changing from ${userData.external_id} to ${externalUserId}`
      );
      const chatbotVisible = isChatbotVisible(externalUserId, isAbEnabled);
      await usersService.updateUserInfo(
        savedUserId,
        externalUserId,
        chatbotVisible
      );
      dispatch(setDisplayChatbot(chatbotVisible));
      if (externalUserId && externalUserId !== '') {
        setIsLoggedInUser(true);
      }
    }
    if (!isPasswordEnabled) dispatch(setIsValidUser(true));
    dispatch(setDisplayChatbot(userData.chatbot_visible));
  }

  async function startStandaloneChatbot() {
    if (company && company !== undefined) {
      console.info('Stating chatbot with sandbox settings for:', company);
      // TODO handle sandbox settings - preferably delete this entire functionality
      getSandboxSettings(company);
    }
    if (isFullScreenModeEnabled) {
      console.info('Starting chatbot in fullscreen mode');
      const { isOriginValid, botVariant } = await validateIframeOrigin(
        window.location.origin
      );
      if (!isOriginValid) {
        dispatch(setDisplayChatbot(false));
        return;
      }
      if (botVariant) {
        dispatch(updateSavedSettings({ botVariantSelection: botVariant }));
      }

      dispatch(setOpenChatWindow('OPEN'));
    }
    initiateUserAndConversation();
  }

  function parseURLParameters() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    console.info(
      urlParams.get('cookies')
        ? `Cookies found: ${urlParams.get('cookies')}`
        : 'no cookies'
    );
    return {
      originUrl: urlParams.get('originUrl') || '',
      isMobile: urlParams.get('isMobile') === 'true',
      externalUserId: urlParams.get('userId') || '',
      cookies: urlParams.get('cookies') || '',
      isCustomIframeEnabled: urlParams.get('isCustomIframeEnabled') === 'true',
    };
  }

  useEffect(() => {
    async function handleLoginEvent() {
      console.info(`Handling login event for user ID: ${currentUser}`);
      dispatch(setWaitingForBotReply(true));
      dispatch(setIsInputDisabled(true));
      await eventsService.handleLoginEvent(currentUser);
      dispatch(setWaitingForBotReply(false));
      dispatch(setIsInputDisabled(false));
    }

    if (!isLoggedInUser) return;
    handleLoginEvent();
  }, [isLoggedInUser]);

  useEffect(() => {
    async function fetchData() {
      if (!isSettingsPresetLoaded || currentUser) return;
      const {
        originUrl,
        isMobile,
        externalUserId,
        isCustomIframeEnabled,
        cookies,
      } = parseURLParameters();
      console.info(
        `Parsed URL parameters: originUrl: ${originUrl}, isMobile: ${isMobile}, externalUserId: ${externalUserId}, isCustomIframeEnabled: ${isCustomIframeEnabled}`
      );
      if (originUrl == '' || originUrl === window.location.origin) {
        dispatch(setIsEmbeddedModeEnabled(false));
        startStandaloneChatbot();
      } else {
        console.info('Starting chatbot in embedded mode:', originUrl);
        dispatch(setIsEmbeddedModeEnabled(true));
        dispatch(setIsFullScreenModeEnabled(isCustomIframeEnabled));
        if (isCustomIframeEnabled) dispatch(setOpenChatWindow('OPEN'));
        try {
          const { isOriginValid, botVariant } = await validateIframeOrigin(
            originUrl
          );
          if (!isOriginValid) {
            dispatch(setDisplayChatbot(false));
            return;
          }
          setIframeOriginUrl(originUrl);
          console.info(
            `Valid event origin: ${originUrl}. Starting chatbot in embedded mode ${
              botVariant ? `with variant ${botVariant}` : ''
            }`
          );
          dispatch(setIsMobileModeEnabled(isMobile));
          if (botVariant) {
            dispatch(updateSavedSettings({ botVariantSelection: botVariant }));
          }
          if (cookies) {
            console.info('Saving found cookies...');
            const updatedCookies = `${cookies}; theurl=${originUrl}`;
            dispatch(setSavedCookies(updatedCookies));
          }
          await initiateUserAndConversation(externalUserId, true);
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    }
    fetchData();
  }, [isSettingsPresetLoaded, currentUser]); // TODO use a better variable

  useEffect(() => {
    if (!iframeOriginUrl || iframeOriginUrl === '') return;
    if (currentUser && currentUser !== '') {
      window.parent.postMessage(
        {
          type: 'SESSION_ID',
          sessionId: currentUser,
        },
        iframeOriginUrl
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (!iframeOriginUrl || iframeOriginUrl === '') return;

    console.info(
      'Chatbot window state changed:',
      !displayChatbot ? 'HIDDEN' : openChatWindow
    );

    const chatbotState = !displayChatbot
      ? 'hidden'
      : openChatWindow.toLowerCase();

    window.parent.postMessage(
      {
        type: 'CHATBOT_STATE',
        state: chatbotState,
        skin: currentSkinName,
      },
      iframeOriginUrl
    );
  }, [openChatWindow, iframeOriginUrl, displayChatbot]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      dispatch(setIsMobileModeEnabled(true));
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      console.info('Checking idle timeout...');
      checkIdleTimeout();
    }
  }, [currentUser]);

  const OpenButton = currentSkin.components.openButton;

  return (
    <>
      {displayChatbot ? (
        <div
          className={`main ${openChatWindow.toLowerCase()} ${
            isEmbeddedModeEnabled ? 'embedded' : ''
          } ${isMobileModeEnabled ? 'mobile' : ''} ${currentSkinName}`}
        >
          {!isEmbeddedModeEnabled && demoMode ? (
            <img src={getFileSrc(botBackground)} className='user-background' />
          ) : null}
          {openChatWindow === 'OPEN' && <ChatContainer />}
          {openChatWindow === 'PREVIEW' && <ChatPreview />}
          {!isFullScreenModeEnabled ? <OpenButton /> : null}
          {!isEmbeddedModeEnabled && isErrorDisplayEnabled ? (
            <ToastContainer
              autoClose={3000}
              closeOnClick
              pauseOnFocusLoss={false}
              theme='light'
            />
          ) : null}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Home;
