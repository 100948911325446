import apiClient from '@utils/axios';
import { BACKEND_URL } from '@utils/constants';


const usersService = {
  async addNewUser(
    userId: string,
    externalUserId: string,
    chatbotVisible: boolean
  ) {
    try {
      const response = await apiClient.post(`${BACKEND_URL}/users/`, {
        user_id: userId,
        external_id: externalUserId,
        chatbot_visible: chatbotVisible,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getSavedUserInfo(user_id: string) {
    try {
      const response = await apiClient.get(`${BACKEND_URL}/users/${user_id}`);
      const { user_found: userFound, user_data: userData } = response.data;
      return { userFound, userData };
    } catch (error) {
      throw error;
    }
  },
  async updateUserInfo(
    user_id: string,
    external_id: string,
    chatbot_visible?: boolean
  ) {
    try {
      const response = await apiClient.put(`${BACKEND_URL}/users/${user_id}`, {
        external_id: external_id,
        chatbot_visible: chatbot_visible,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
export default usersService;
