import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import {
  restartChatWithNewUser,
  setShowSettings,
} from '@states/slices/chatbotSlice';
import Restart from '@assets/images/restart.svg';
import Settings from '@assets/images/settings.svg';
import CloseButton from '../CloseButton';
import './HeaderBar.scss';

function HeaderBar() {
  const dispatch = useDispatch();
  const {
    currentLanguage,
    isSettingsPresetLoaded,
    isSettingsEnabled,
    isRestartEnabled,
    isFullScreenModeEnabled,
  } = useSelector((state: RootState) => state.settings);
  const { isValidUser } = useSelector((state: RootState) => state.chatbot);

  return (
    <div className='header-bar'>
      {isSettingsEnabled && isValidUser && isSettingsPresetLoaded ? (
        <div
          className='settings-button'
          onClick={() => dispatch(setShowSettings(true))}
        >
          <img src={Settings} alt='Chatbot settings' />
        </div>
      ) : null}
      {isRestartEnabled && isValidUser && isSettingsPresetLoaded ? (
        <div
          className='restart-button'
          onClick={() => {
            dispatch(restartChatWithNewUser(currentLanguage));
          }}
        >
          <img src={Restart} alt='New conversation' />{' '}
        </div>
      ) : null}
      {!isFullScreenModeEnabled && <CloseButton />}
    </div>
  );
}

export default HeaderBar;
